<template>
  <div>
    <stepNav :name="$t('roof.pick_rooftile')" :validStep="isStepComplete(['rooftile', 'rooftileColor', 'rooftileTreatment'])"/>
    <radioButtonGroup
      :label="$t('step.roof_tile')"
      :options="rooftiles"
      v-model="rooftile"
      @change="[validateRooftileTreatment(), validateRooftileColor()]"
    />
    <radioButtonGroup
      v-if="rooftile"
      :label="$t('breadcrumb.tile_treatment')"
      :options="rooftileTreatments"
      v-model="rooftileTreatment"
      @change="validateRooftileColor"
    />
    <radioButtonGroup
      v-if="rooftile && rooftileTreatment"
      :label="$t('breadcrumb.tile_color')"
      :options="rooftileColors"
      v-model="rooftileColor"
    />
    <optionItemImg
      v-if="rooftile && rooftileArticle"
      :option="rooftileArticle"
      :selected="rooftileColor !== null"
    ></optionItemImg>
  </div>
</template>

<script>
import stepValidation from '../../mixins/stepValidation'
export default {
  name: 'chooseRoofTile',
  data: function () {
    return {}
  },
  mixins: [
    stepValidation
  ],
  computed: {
    products() {
      return this.$store.getters.getProducts;
    },
    rooftiles() {
      return this.$store.getters.getRooftiles;
    },
    rooftile: {
      get() {return this.$store.getters.getSelectedValue('rooftile')},
      set(value) {this.$store.dispatch('updateValue', {name: 'rooftile', value: value})}
    },
    rooftileTreatments() {
      return this.$store.getters.getAvailableTreatments;
    },
    rooftileTreatment: {
      get() {return this.$store.getters.getSelectedValue('rooftileTreatment')},
      set(value) {this.$store.dispatch('updateValue', {name: 'rooftileTreatment', value: value})}
    },
    rooftileColors() {
      return this.$store.getters.getAvailableColors;
    },
    rooftileColor: {
      get() {return this.$store.getters.getSelectedValue('rooftileColor')},
      set(value) {this.$store.dispatch('updateValue', {name: 'rooftileColor', value: value})}
    },
    rooftileArticle() {
      if (this.rooftile === null || this.rooftile === undefined) return null
      let product = this.products.find(p => p.type === 'rooftile' && p.name === this.rooftile)

      let variant = product.variants[0]

      if(this.rooftileTreatment) {
        variant = product.variants.find(v => v.treatment == this.rooftileTreatment)
      }

      if(this.rooftileTreatment && this.rooftileColor) {
        variant = product.variants.find(v => v.treatment == this.rooftileTreatment && v.color == this.rooftileColor)
      }

      let fullProduct = {...product, ...variant}
      return fullProduct
    }
  },
  methods: {
    validateRooftileTreatment(){
      if (!this.rooftile) return;
      let validValue = this.rooftileTreatments.includes(this.rooftileTreatment)
      if (!validValue) {
        this.$store.dispatch('resetValue', 'rooftileTreatment')
      }
      if(this.rooftileTreatments.length === 1) {
        this.rooftileTreatment = this.rooftileTreatments[0].id
      }
    },
    validateRooftileColor(){
      if (!this.rooftile) return;
      let validValue = this.rooftileColors.includes(this.rooftileColor)
      if (!validValue) {
        this.$store.dispatch('resetValue', 'rooftileColor')
      }
      if(this.rooftileColors.length === 1) {
        this.rooftileColor = this.rooftileColors[0].id
      }
    },
    goToPrevStep() {
      this.$store.dispatch('previousStep')
    },
    goToNextStep() {
      this.$store.dispatch('nextStep')
    },
  },
  components: {
    // optionItems: () => import("../components/optionItems"),
    stepNav: () => import("../stepNav"),
    radioButtonGroup: () => import("../radioButtonGroup"),
    optionItemImg: () => import("../optionItemImg"),
  }
}
</script>

<style scoped>
.help-text {
  text-align: left
}
label {
  display: inline-block;
  width: 150px;
  color: #464749;
  font-size: 1rem;
  font-weight: 700;
  text-align: right;
  padding: 0 5px;
}
.is-warning {
  /* color: rgb(166,25,46); */
  font-weight: bold;
}
.form-group {
  margin: 15px;
}
.help-text {
  text-align: left
}
.radiobtn label {
  background-color: blue;
}

/* .image-container .check {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: rgb(166,25,46);
  position: absolute;
  top: -3px;
  right: -3px;
  padding: 6px;
  opacity: 1;
}
.image-container .check svg {
  width: 11px;
  height: 11px;
} */

</style>
